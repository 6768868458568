.containerStep16 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    padding-left: 13%;
    margin: 4% ;
  }
  
  .container-1Step16 {
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
  }
  
  .imageStep16 {
    background-size: cover;
    background-position: center;
    width: 77px;
    height: 193px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    margin-left: 4px;
    object-fit: cover;
    border-style: solid;
    border-width: 4px;
    border-color: steelblue;
  }
  
  .textStep16 {
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
    font-family: 'Scada';
    line-height: 23px;
    color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    width:120px;
  }
  
  .container-2Step16 {
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 7px;
  }
  
  .image-1Step16 {
    background-size: cover;
    background-position: center;
    width: 82px;
    height: 191px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    align-self: flex-end;
    object-fit: cover;
  }
  
  .text-1Step16 {
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
    font-family: 'Scada';
    line-height: 23px;
    color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    width:120px;

  }
  
  .container-3Step16 {
    height: 95%;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 3px;
    margin-bottom: 5px;
  }
  
  .image-2Step16 {
    background-size: cover;
    background-position: center;
    width: 79px;
    height: 180px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    object-fit: cover;
  }
  
  .text-2Step16 {
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
    font-family: 'Scada';
    line-height: 23px;
    color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    padding: 4px;
  }
  
  .container-4Step16 {
    height: 95%;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-left: 9px;
    margin-top: 2px;
  }

  .container-5Step16 {
    height: 95%;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
    margin-left: 88px;
    margin-top: 92px;
  
  }
  
  .image-3Step16 {
    background-size: cover;
    background-position: center;
    width: 80px;
    height: 180px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    object-fit: cover;
  }
  
  .text-3Step16 {
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
    font-family: 'Scada';
    line-height: 23px;
    color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    object-fit: cover;
  }

  .buttonStyleActorStep16{
    width: 100%;
    margin-left: 0%;
  }

  .containerRightStep16 {
    width: 40%;
    height: 100%;
    border-radius: 10px;
    padding: 15px;
    margin: 4% ;
  }
  