
.outlinerTextTitle{
    width: 100%;
    height: 100%;
    margin-bottom: 10%;
    margin-top: 1%;
}

.textTitle {
    text-align: center;
    font-size: 3rem;
    font-weight: 700;
    font-family: 'Scheherazade';
    line-height: 56px;
    color: #a7a169;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
  }
  
  