.buttonInLeftSign {
    border-radius: 4px;
    background-color: #0d6efd;
    width: 20%;
    height: 30%;
    justify-content: center;
  }
  
  .textInLeftSign {
    font-size: 1.25rem;
    font-weight: 400;
    font-family: 'Scada';
    line-height: 23px;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
  }
  
  