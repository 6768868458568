.outlinerLeftSign{
    width: 20%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;


    margin-top: 7%;
    margin-left: 1%;
    margin-right: 4%;
    
  }
  
  .inlinerLeftSign {
    border-radius: 15px;
    background-color: #f3f09e;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-basis: 87%;
  }
  
  .textLeftSign {
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
    font-family: 'Scada';
    line-height: 23px;
    color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
  }
  
  .buttonLeftSign {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-basis: 8%;
  }
  
  