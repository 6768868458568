
.outlinerStep0Title {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
  }
  
  .inlinerStep0Title {
    border-radius: 15px;
    background-color: #f3f09e;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-basis: 100%;
    padding-left: 12px;
    padding-bottom: 2px;
  }
  
  .textStep0Title {
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
    font-family: 'Scada';
    line-height: 23px;
    color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    margin-left: 6px;
    margin-bottom: 1px;
  }
  
  
  