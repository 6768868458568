.containerStep2 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    padding-left: 3px;
    padding-bottom: 10px;
    margin: 4% ;
  }
  
  .container-1Step2 {
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
  }
  
  .imageStep2 {
    background-size: cover;
    background-position: center;
    width: 77px;
    height: 193px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    margin-left: 4px;
    object-fit: cover;
  }
  
  .textStep2 {
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
    font-family: 'Scada';
    line-height: 23px;
    color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
  }
  
  .container-2Step2 {
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 7px;
  }
  
  .image-1Step2 {
    background-size: cover;
    background-position: center;
    width: 82px;
    height: 191px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    align-self: flex-end;
    object-fit: cover;
  }
  
  .text-1Step2 {
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
    font-family: 'Scada';
    line-height: 23px;
    color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
  }
  
  .container-3Step2 {
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 3px;
    margin-bottom: 5px;
  }
  
  .image-2Step2 {
    background-size: cover;
    background-position: center;
    width: 79px;
    height: 193px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    object-fit: cover;
  }
  
  .text-2Step2 {
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
    font-family: 'Scada';
    line-height: 23px;
    color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    padding: 4px;
  }
  
  .container-4Step2 {
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-left: 9px;
    margin-top: 2px;

    border-style: solid;
    border-width: 4px;
    border-color: steelblue;
  }
  
  .image-3Step2 {
    background-size: cover;
    background-position: center;
    width: 125px;
    height: 195px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    object-fit: cover;
  }
  
  .text-3Step2 {
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
    font-family: 'Scada';
    line-height: 23px;
    color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
  }
  