.frame-5Step5 {
    color: #000;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    font-family: Scada;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 23px;
    display: flex;
    padding-top: 5%;
  }
  
  .cat-absolute-containerStep5 {
    width: 100%;
    z-index: 1;
    flex-direction: row;
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: -680px;
    padding-bottom: 30px;
    padding-right: 14px;
    display: flex;
  }
  
  .flex-containerStep5 {
    text-align: center;
    height: 80%;
    flex-direction: column;
    flex-basis: 24%;
    justify-content: space-between;
    align-items: center;
    margin-top: 3px;
    margin-bottom: 3px;
    display: flex;
  }
  
  .rectangle-13Step5 {
    width: 100%;
    background-color: #f3f09e;
    border-radius: 15px;
    flex-direction: row;
    flex-basis: 51%;
    justify-content: center;
    align-items: center;
    padding-top: 7px;
    padding-right: 2px;
    display: flex;
    margin-bottom: 15%;
  }
  
  .the-seller-will-go-tStep5 {
    margin-top: 4px;
  }
  
  .sellerStep5 {
    width: 79px;
    height: 193px;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    margin-bottom: 1px;
  }
  
  .rectangle-15Step5 {
    color: #575555;
    height: 70%;
    background-color: #f2f2f2;
    border-radius: 12px;
    flex-direction: column;
    flex-basis: 65%;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 17px;
    margin-left: 28px;
    padding-bottom: 5px;
    padding-left: 6px;
    padding-right: 23px;
    display: flex;
  }

  .display-notifStep5{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .rectangle-16Step5 {
    text-align: center;
    color: #fff;
    width: 90%;
    background-color: #dc7870;
    border-radius: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    margin-left: 2px;
    margin-top: 5px;
    padding-top: 1px;
    padding-right: 4px;
    display: flex;
  }
  
  .flex-container-1Step5 {
    width: 85%;
    flex-direction: row;
    flex-basis: 70%;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 3px;
    margin-bottom: 3px;
    display: flex;
  }
  
  .rectangle-17Step5 {
    width: 268px;
    height: 298px;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    border-radius: 2px;
  }
  
  .flex-container-2Step5 {
    height: 100%;
    flex-direction: column;
    flex-basis: 51%;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
  
  .rectangle-18Step5 {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    flex-direction: row;
    flex-basis: 81%;
    justify-content: center;
    align-items: stretch;
    padding-top: 6px;
    padding-right: 8px;
    display: flex;

    margin-bottom: 6px;
  }
  
  .next-instance-1Step5 {
    width: 45%;
    flex-basis: 15%;
    margin-left: 6px;
  }
  