.frame-5Step3 {
    color: #000;
    text-align: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    font-family: Scada;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 23px;
    display: flex;

    padding-top: 5%;
  }
  
  .cat-absolute-containerStep3  {
    width: 100%;
    z-index: 1;
    flex-direction: row;
    flex-basis: 100%;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: -680px;
    padding-bottom: 18px;
    padding-right: 24px;
    display: flex;
  }
  
  .flex-containerStep3  {
    height: 80%;
    flex-direction: column;
    flex-basis: 24%;
    justify-content: space-between;
    align-items: center;
    margin-top: 2px;
    margin-bottom: 2px;
    display: flex;
  }
  
  .rectangle-13Step3  {
    width: 100%;
    background-color: #f3f09e;
    border-radius: 15px;
    flex-direction: row;
    flex-basis: 50%;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;
    padding-bottom: 18px;
    padding-right: 5px;
    padding-top: 10px;
    display: flex;
  }
  
  .the-buyer-go-to-theStep3  {
    margin-bottom: 9px;
  }
  
  .familyStep3  {
    width: 125px;
    height: 195px;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    margin-top: 1px;
  }
  
  .rectangle-15Step3  {
    height: 70%;
    background-color: #f2f2f2;
    border-radius: 12px;
    flex-direction: column;
    flex-basis: 66%;
    justify-content: space-around;
    align-items: center;
    margin-top: 16px;
    padding-bottom: 9px;
    padding-left: 11px;
    padding-right: 12px;
    display: flex;
  }
  
  .rectangle-16Step3  {
    color: #fff;
    width: 85%;
    background-color: #dc7870;
    border-radius: 10px;
    flex-direction: row;
    flex-basis: 9%;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 12px;
    padding-top: 1px;
    padding-right: 4px;
    display: flex;
  }
  
  .flex-container-1Step3  {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 6px;
    margin-bottom: 5px;
    margin-left: 2px;
    display: flex;
  }
  
  .rectangle-19Step3 , .rectangle-20Step3 , .rectangle-21Step3  {
    width: 30%;
    height: 75%;
    border-radius: 10px;
  }
  
