

.buttonStyleGeneralStep2{
  width: 25%;
  margin-left: 83%;
}

.rectangle-25Step2 {
  color: #f7e7e7;
  height: 70%;
  background-color: #9b9595;
  padding-left: 6px;
  padding-right: 23px;
  padding-top: 2px;
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 8px;
}

