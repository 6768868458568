

.buttonStyleGeneralStep10{
  width: 25%;
  margin-left: 0%;
}

.rectangle-25Step12 {
  color: #f7e7e7;
  height: 70%;
  background-color: #9c0909;
  padding-left: 6px;
  padding-right: 23px;
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 400;
  font-family: 'Scada';
}

