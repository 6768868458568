
.frame-5Step9 {
    color: #000;
    text-align: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    font-family: Scada;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 23px;
    display: flex;

    padding-top: 5%;
  }
  
  .cat-absolute-containerStep9  {
    width: 100%;
    z-index: 1;
    flex-direction: row;
    flex-basis: 100%;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: -680px;
    padding-bottom: 18px;
    padding-right: 24px;
    display: flex;
  }
  
  .flex-containerStep9  {
    height: 80%;
    flex-direction: column;
    flex-basis: 24%;
    justify-content: space-between;
    align-items: center;
    margin-top: 2px;
    margin-bottom: 2px;
    display: flex;
  }
  
  .rectangle-13Step9  {
    width: 100%;
    background-color: #f3f09e;
    border-radius: 15px;
    flex-direction: row;
    flex-basis: 50%;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;
    padding-bottom: 18px;
    padding-right: 5px;
    padding-top: 10px;
    display: flex;
  }
  
  .the-buyer-go-to-theStep9  {
    margin-bottom: 9px;
  }
  
  .familyStep9  {
    width: 125px;
    height: 195px;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    margin-top: 1px;
  }
  
  .rectangle-15Step9  {
    height: 70%;
    background-color: #f2f2f2;
    border-radius: 12px;
    flex-direction: column;
    flex-basis: 66%;
    justify-content: space-around;
    align-items: center;
    margin-top: 16px;
    padding-bottom: 9px;
    padding-left: 11px;
    padding-right: 12px;
    display: flex;
  }
  
  .rectangle-16Step9  {
    color: #fff;
    width: 85%;
    background-color: #dc7870;
    border-radius: 10px;
    flex-direction: row;
    flex-basis: 9%;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 12px;
    padding-top: 1px;
    padding-right: 4px;
    display: flex;
  }
  
  .flex-container-1Step9  {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 6px;
    margin-bottom: 5px;
    margin-left: 2px;
    display: flex;
  }
  
  .rectangle-19Step9 , .rectangle-20Step9 , .rectangle-21Step9  {
    width: 30%;
    height: 75%;
    border-radius: 10px;
  }


  .frame-7Step9 {
    width: 100%;
    height: 70%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }


  .rectangle-23Step9 {
    color: #fff;
    width: 22%;
    z-index: 1;
    background-color: #342f2f;
    border: none;
    flex-direction: row;
    flex-basis: 7%;
    justify-content: center;
    align-items: center;
    margin-bottom: -21px;
    margin-left: 1px;
    padding-top: 2px;
    padding-right: 1px;
    font-family: Scada;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 25px;
    display: flex;
  }
  
  .smart-contractStep9 {
    margin-top: 1px;
  }

  .rectangle-22Step9 {
    width: 60%;
    height: 100%;
    background-color: #f8a95f;
    border-radius: 15px;
    padding: 5%;

  }

  .frame-5Step9 {
    color: #000;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    font-family: Scada;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 23px;
    display: flex;
    padding-top: 2%;
  }

  .cat-absolute-containerStep9 {
    width: 100%;
    z-index: 1;
    flex-direction: row;
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: -680px;
    padding-bottom: 30px;
    padding-right: 14px;
    display: flex;
  }

  .flex-containerStep9 {
    text-align: center;
    height: 80%;
    flex-direction: column;
    flex-basis: 24%;
    justify-content: space-between;
    align-items: center;
    margin-top: 3px;
    margin-bottom: 3px;
    margin-left: 5%;
    display: flex;
  }
  
  .rectangle-13Step9 {
    width: 100%;
    background-color: #f3f09e;
    border-radius: 15px;
    flex-direction: row;
    flex-basis: 51%;
    justify-content: center;
    align-items: center;
    padding-top: 7px;
    padding-right: 2px;
    display: flex;
    margin-bottom: 15%;
  }

  .familyStep8  {
    width: 125px;
    height: 195px;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    margin-top: 1px;
  }

  .flex-container-1Step9 {
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3px;
    margin-bottom: 3px;
    display: flex;
  }

  .rectangle-17Step9 {
    width: 125px;
    height: 300px;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    border-radius: 2px;
    margin-right: 2% ;
  }

  .flex-container-2Step9 {
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
  
  .rectangle-18Step9 {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding-top: 6px;
    padding-right: 8px;

    margin-bottom: 6px;
    margin-left: '3%';
  }
  
  .next-instance-1Step9 {
    width: 45%;
    flex-basis: 15%;
    margin-left: 6px;
  }
  

  
  
